import React from 'react';

import { Button } from '@chakra-ui/react';

import './Connecter.css';

var Connecter = ({accounts, setAccounts}) => {
    const isConnected = Boolean(accounts[0]);
    //const binanceTestChainId = ['0x61', 97]


    async function updateData(){
        if(window.ethereum) {
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
        setAccounts(accounts);
        }
        const chainId = window.ethereum.networkVersion;
        console.log(chainId);
        if(chainId === 271){
            console.log("Bravo!, you are on the correct network");
        } else {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainName: 'XO testnet L2',
                    chainId: '0x10F',
                    nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
                    rpcUrls: ['https://zkrpc.hyperscreener.com/'],
                    blockExplorerUrls: [
                      "https://xo.hyperscreener.com/"
                    ]
                  }
                ]
              });  

        console.log("oulalal, switch to the correct network")
        }
    }



    return(
        <div className = "Connecter">
            {isConnected ? (
                <Button onClick={updateData}>
                {"0x" + accounts[0][2]+accounts[0][3]+"..."} is Connected
                </Button>
            ):(
                <div>
                <Button onClick={updateData}>
                    Connect
                </Button>    
                </div>

            )}
        </div>
    )

}
export default Connecter;
